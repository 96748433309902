import React, { useState, useEffect } from 'react';
import './Cursor.css';

const Cursor = ({ setAngle }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const setFromEvent = e => {
      const x = e.clientX;
      const y = e.clientY;
      setPosition({ x, y });

      const logo = document.querySelector('.App-logo');
      const rect = logo.getBoundingClientRect();
      const logoX = rect.left + rect.width / 2;
      const logoY = rect.top + rect.height / 2;
      const dx = x - logoX;
      const dy = y - logoY;
      const angle = Math.atan2(dy, dx) * 180 / Math.PI;
      setAngle(angle + 90); // +90 to correct the initial rotation
    };
    window.addEventListener("mousemove", setFromEvent);

    return () => {
      window.removeEventListener("mousemove", setFromEvent);
    };
  }, [setAngle]);

  return <div className="cursor" style={{ left: `${position.x}px`, top: `${position.y}px` }} />;
};

export default Cursor;