import logo from './cat-animal-icon.svg';
import React, { useState } from 'react';
import Cursor from './Cursor';
import './App.css';

function App() {
  const [angle, setAngle] = useState(0);

  return (
    <div className="App">
      <Cursor setAngle={setAngle} />
      <header className="App-header">
        
        <h1>Joe Bekiranov</h1>
        <h2>VT '25 CPE CS Math</h2>
        <img src={logo} className="App-logo" alt="logo" style={{ transform: `rotate(${angle}deg)` }}/>
        <p>
          Important Links Below
        </p>
        <a
          className="App-link"
          href="/resume.pdf"
          target="_blank"
          rel="noopener noreferrer"
          
          
        >
          Resume
        </a>
        <a
          className="App-link"
          href="https://github.com/JoeBek"
          target="_blank"
          rel="noopener noreferrer"
          >
            Github
          </a>
      </header>
    </div>
  );
}

export default App;